"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentErrorActions = exports.CartKind = exports.PriceFormat = exports.PaymentMethods = exports.AppliedCouponStatus = void 0;
var AppliedCouponStatus;
(function (AppliedCouponStatus) {
    AppliedCouponStatus["INVALID"] = "invalid";
    AppliedCouponStatus["INVALID_BY_VALUE"] = "invalid_by_value";
    AppliedCouponStatus["INVALID_BY_PAYMENT_METHOD"] = "invalid_by_payment_method";
    AppliedCouponStatus["UNSET"] = "unset";
    AppliedCouponStatus["VALID"] = "valid";
})(AppliedCouponStatus = exports.AppliedCouponStatus || (exports.AppliedCouponStatus = {}));
var PaymentMethods;
(function (PaymentMethods) {
    PaymentMethods["CREDIT_CARD"] = "credit_card";
    PaymentMethods["BANK_SLIP"] = "bank_slip";
    PaymentMethods["PIX"] = "pix";
    PaymentMethods["MULTICARD"] = "multicard";
})(PaymentMethods = exports.PaymentMethods || (exports.PaymentMethods = {}));
var PriceFormat;
(function (PriceFormat) {
    PriceFormat["FULL"] = "full";
    PriceFormat["INSTALLMENTS"] = "installments";
})(PriceFormat = exports.PriceFormat || (exports.PriceFormat = {}));
var CartKind;
(function (CartKind) {
    CartKind["COMMOM"] = "common";
    CartKind["SUBSCRIPTION"] = "subscription";
})(CartKind = exports.CartKind || (exports.CartKind = {}));
var PaymentErrorActions;
(function (PaymentErrorActions) {
    PaymentErrorActions["EDIT_USER_DATA"] = "editUserData";
    PaymentErrorActions["EDIT_CREDIT_CARD"] = "editCreditCard";
    PaymentErrorActions["GENERATE_BANK_SLIP"] = "generateBankSlip";
    PaymentErrorActions["GENERATE_PIX"] = "generatePix";
    PaymentErrorActions["CLOSE"] = "close";
    PaymentErrorActions["REFRESH_PAGE"] = "refresh_page";
    PaymentErrorActions["TRY_AGAIN"] = "try_again";
    PaymentErrorActions["OK"] = "ok";
})(PaymentErrorActions = exports.PaymentErrorActions || (exports.PaymentErrorActions = {}));
;
