"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validPhoneNumber = exports.validCpfCnpj = void 0;
function validCpfCnpj(cpfCnpj) {
    var DocumentNumber = cpfCnpj.replace(/[^\d]+/g, "");
    if (DocumentNumber.length === 11) {
        var arrayCpf = [
            "00000000000",
            "11111111111",
            "22222222222",
            "33333333333",
            "44444444444",
            "55555555555",
            "66666666666",
            "77777777777",
            "88888888888",
            "99999999999",
        ];
        if (arrayCpf.includes(DocumentNumber)) {
            return false;
        }
        var number = 0;
        var caracter = "";
        var numbers = "0123456789";
        var j = 10;
        var summation = 0;
        var remainder = 0;
        var digit1 = 0;
        var digit2 = 0;
        var DocumentNumberAux = "";
        DocumentNumberAux = DocumentNumber.substring(0, 9);
        for (var i = 0; i < 9; i += 1) {
            caracter = DocumentNumberAux.charAt(i);
            if (numbers.search(caracter) === -1) {
                return false;
            }
            number = Number(caracter);
            summation += number * j;
            j -= 1;
        }
        remainder = summation % 11;
        digit1 = 11 - remainder;
        if (digit1 > 9) {
            digit1 = 0;
        }
        j = 11;
        summation = 0;
        DocumentNumberAux += digit1;
        for (var i = 0; i < 10; i += 1) {
            caracter = DocumentNumberAux.charAt(i);
            number = Number(caracter);
            summation += number * j;
            j -= 1;
        }
        remainder = summation % 11;
        digit2 = 11 - remainder;
        if (digit2 > 9) {
            digit2 = 0;
        }
        DocumentNumberAux += digit2;
        if (DocumentNumber !== DocumentNumberAux) {
            return false;
        }
        return true;
    }
    if (DocumentNumber.length === 14) {
        var arrayCnpj = [
            "00000000000000",
            "11111111111111",
            "22222222222222",
            "33333333333333",
            "44444444444444",
            "55555555555555",
            "66666666666666",
            "77777777777777",
            "88888888888888",
            "99999999999999",
        ];
        if (arrayCnpj.includes(DocumentNumber)) {
            return false;
        }
        var cnpj = DocumentNumber.trim();
        var v1 = 0;
        var v2 = 0;
        var aux = false;
        for (var i = 1; cnpj.length > i; i += 1) {
            if (cnpj[i - 1] !== cnpj[i]) {
                aux = true;
            }
        }
        if (aux === false) {
            return false;
        }
        for (var i = 0, p1 = 5, p2 = 13; cnpj.length - 2 > i; i += 1, p1 -= 1, p2 -= 1) {
            if (p1 >= 2) {
                v1 += cnpj[i] * p1;
            }
            else {
                v1 += cnpj[i] * p2;
            }
        }
        v1 %= 11;
        if (v1 < 2) {
            v1 = 0;
        }
        else {
            v1 = 11 - v1;
        }
        var digit1 = parseFloat(cnpj[12]);
        if (v1 !== digit1) {
            return false;
        }
        for (var i = 0, p1 = 6, p2 = 14; cnpj.length - 1 > i; i += 1, p1 -= 1, p2 -= 1) {
            if (p1 >= 2) {
                v2 += cnpj[i] * p1;
            }
            else {
                v2 += cnpj[i] * p2;
            }
        }
        v2 %= 11;
        if (v2 < 2) {
            v2 = 0;
        }
        else {
            v2 = 11 - v2;
        }
        var digit2 = parseFloat(cnpj[13]);
        if (v2 !== digit2) {
            return false;
        }
        return true;
    }
    return false;
}
exports.validCpfCnpj = validCpfCnpj;
function validPhoneNumber(TelephoneNumber) {
    var phone = TelephoneNumber.trim();
    var ddd1 = phone[1];
    var phone1 = phone[5];
    if (ddd1 === "0" || phone1 === "0") {
        return false;
    }
    if (TelephoneNumber.length === 14 || TelephoneNumber.length === 15) {
        return true;
    }
    return false;
}
exports.validPhoneNumber = validPhoneNumber;
