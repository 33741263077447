"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
var composition_api_1 = require("@nuxtjs/composition-api");
var payment_1 = require("@/helpers/payment");
var composables_1 = require("@/composables");
var types_1 = require("@/models/types");
var state_1 = require("@/composables/state");
function default_1() {
    var absorbentFees = composition_api_1.computed(function () { return !!state_1.payment.value.absorbent_fees; });
    var acceptsBankSlip = composition_api_1.computed(function () {
        return state_1.payment.value.accepted_payment_methods.includes(types_1.PaymentMethods.BANK_SLIP) && !state_1.purchase.value.buyer.foreigner;
    });
    var acceptsCreditCard = composition_api_1.computed(function () {
        return state_1.payment.value.accepted_payment_methods.includes(types_1.PaymentMethods.CREDIT_CARD);
    });
    var acceptsMulticard = composition_api_1.computed(function () {
        var hasOrderbump = composables_1.useOrderbump().hasOrderbump;
        var hasCoproduction = !!state_1.product.value.coproducers.has_coproducers;
        var hasMulticardPaymentMethod = state_1.payment.value.accepted_payment_methods.includes(types_1.PaymentMethods.MULTICARD);
        return (hasMulticardPaymentMethod && !(hasOrderbump.value && hasCoproduction));
    });
    var acceptsPix = composition_api_1.computed(function () {
        return state_1.payment.value.accepted_payment_methods.includes(types_1.PaymentMethods.PIX) &&
            !state_1.purchase.value.buyer.foreigner;
    });
    var acceptsPixInstallment = composition_api_1.computed(function () {
        return acceptsPix.value &&
            state_1.payment.value.pix_installment &&
            state_1.payment.value.pix_simulation.length > 1;
    });
    var acceptsBankSlipInstallment = composition_api_1.computed(function () {
        return acceptsBankSlip.value &&
            state_1.payment.value.bank_slip_installment &&
            state_1.payment.value.bank_slip_simulation.length > 1;
    });
    var cashValue = composition_api_1.computed(function () {
        return hasDiscount.value
            ? payment_1.toCentsNumber(state_1.coupon.value.discount_price_cents)
            : payment_1.toCentsNumber(state_1.payment.value.price_cents);
    });
    var cashValueCurrency = composition_api_1.computed(function () { return payment_1.toCurrency(cashValue.value); });
    var multicardCurrencyValue = function (selectedMulticard) {
        if (selectedMulticard === void 0) { selectedMulticard = 1; }
        var multicardInstallments = [
            chosenInstallmentFirstMulticard.value,
            chosenInstallmentSecondMulticard.value,
        ];
        var selectedInstallment = multicardInstallment.value.find(function (installment) {
            return (installment.installments ===
                multicardInstallments[selectedMulticard - 1]);
        });
        return payment_1.toCurrency(payment_1.toCentsNumber((selectedInstallment === null || selectedInstallment === void 0 ? void 0 : selectedInstallment.total_cents) || 0));
    };
    var cashValueToString = composition_api_1.computed(function () {
        return payment_1.toCentsString(cashValue.value);
    });
    var chosenInstallment = composition_api_1.computed(function () { return state_1.purchase.value.credit_card.installments || 1; });
    var chosenInstallmentFirstMulticard = composition_api_1.computed(function () { return state_1.purchase.value.first_multicard.installments || 1; });
    var chosenInstallmentSecondMulticard = composition_api_1.computed(function () { return state_1.purchase.value.second_multicard.installments || 1; });
    var chosenInstallmentBankSlip = composition_api_1.computed(function () { return state_1.purchase.value.bank_slip.installments || 1; });
    var chosenInstallmentPix = composition_api_1.computed(function () { return state_1.purchase.value.pix.installments || 1; });
    var couponDiscount = composition_api_1.computed(function () {
        var total = state_1.appliedCouponValue.value
            ? state_1.coupon.value.price_cents - state_1.coupon.value.discount_price_cents
            : 0;
        return payment_1.toCentsNumber(total);
    });
    var couponDiscountCurrency = composition_api_1.computed(function () {
        return payment_1.toCurrency(couponDiscount.value);
    });
    var couponDiscountToString = composition_api_1.computed(function () {
        return payment_1.toCentsString(couponDiscount.value);
    });
    var displayFullPrice = composition_api_1.computed(function () { return isPayingByBankslip.value || isPayingByPix.value; });
    var finalPrice = composition_api_1.computed(function () {
        var _a, _b;
        if (isPayingByCreditCard.value) {
            return payment_1.toCentsNumber((_b = (_a = selectedInstallments.value) === null || _a === void 0 ? void 0 : _a.total_cents) !== null && _b !== void 0 ? _b : 0);
        }
        return cashValue.value;
    });
    var finalPriceCurrency = composition_api_1.computed(function () {
        return payment_1.toCurrency(finalPrice.value);
    });
    var finalPriceToString = composition_api_1.computed(function () {
        return payment_1.toCentsString(finalPrice.value);
    });
    var hasAppliedCouponValue = composition_api_1.computed(function () { return state_1.appliedCouponValue.value !== ""; });
    var hasAppliedCouponStatusUnset = composition_api_1.computed(function () { return state_1.appliedCouponStatus.value === types_1.AppliedCouponStatus.UNSET; });
    var hasAppliedCouponStatusValid = composition_api_1.computed(function () { return state_1.appliedCouponStatus.value === types_1.AppliedCouponStatus.VALID; });
    var hasCoupon = composition_api_1.computed(function () { return !!state_1.coupon.value; });
    var hasDiscount = composition_api_1.computed(function () {
        return !!state_1.appliedCouponValue.value &&
            state_1.appliedCouponStatus.value === types_1.AppliedCouponStatus.VALID;
    });
    var installment = composition_api_1.computed(function () {
        return hasDiscount.value
            ? state_1.coupon.value.credit_card_simulation
            : state_1.payment.value.credit_card_simulation;
    });
    var multicardInstallment = composition_api_1.computed(function () {
        return hasDiscount.value
            ? state_1.coupon.value.multicard_simulation
            : state_1.payment.value.multicard_simulation;
    });
    var bankSlipInstallment = composition_api_1.computed(function () {
        return hasDiscount.value
            ? state_1.coupon.value.bank_slip_simulation
            : state_1.payment.value.bank_slip_simulation;
    });
    var pixInstallment = composition_api_1.computed(function () {
        return hasDiscount.value
            ? state_1.coupon.value.pix_simulation
            : state_1.payment.value.pix_simulation;
    });
    var installmentValuePrice = composition_api_1.computed(function () { var _a, _b; return payment_1.toCentsNumber((_b = (_a = selectedInstallments.value) === null || _a === void 0 ? void 0 : _a.installment_amount_cents) !== null && _b !== void 0 ? _b : 0); });
    var installmentFirstMulticardValuePrice = composition_api_1.computed(function () {
        var _a, _b;
        return payment_1.toCentsNumber((_b = (_a = selectedInstallmentsFirstMulticard.value) === null || _a === void 0 ? void 0 : _a.installment_amount_cents) !== null && _b !== void 0 ? _b : 0);
    });
    var installmentSecondMulticardValuePrice = composition_api_1.computed(function () {
        var _a, _b;
        return payment_1.toCentsNumber((_b = (_a = selectedInstallmentsSecondMulticard.value) === null || _a === void 0 ? void 0 : _a.installment_amount_cents) !== null && _b !== void 0 ? _b : 0);
    });
    var installmentBankSlipValuePrice = composition_api_1.computed(function () {
        var _a, _b;
        return payment_1.toCentsNumber((_b = (_a = selectedInstallmentsBankSlip.value) === null || _a === void 0 ? void 0 : _a.installment_amount_cents) !== null && _b !== void 0 ? _b : 0);
    });
    var installmentPixValuePrice = composition_api_1.computed(function () { var _a, _b; return payment_1.toCentsNumber((_b = (_a = selectedInstallmentsPix.value) === null || _a === void 0 ? void 0 : _a.installment_amount_cents) !== null && _b !== void 0 ? _b : 0); });
    var installmentValuePriceCurrency = composition_api_1.computed(function () {
        return payment_1.toCurrency(installmentValuePrice.value);
    });
    var installmentFirstMulticardPriceCurrency = composition_api_1.computed(function () {
        return payment_1.toCurrency(installmentFirstMulticardValuePrice.value);
    });
    var installmentSecondMulticardPriceCurrency = composition_api_1.computed(function () {
        return payment_1.toCurrency(installmentSecondMulticardValuePrice.value);
    });
    var installmentBankSlipPriceCurrency = composition_api_1.computed(function () {
        return payment_1.toCurrency(installmentBankSlipValuePrice.value);
    });
    var installmentPixPriceCurrency = composition_api_1.computed(function () {
        return payment_1.toCurrency(installmentPixValuePrice.value);
    });
    var installmentValuePriceToString = composition_api_1.computed(function () {
        return payment_1.toCentsString(installmentValuePrice.value);
    });
    var installmentValueFirstMulticardPriceToString = composition_api_1.computed(function () {
        return payment_1.toCentsString(installmentFirstMulticardValuePrice.value);
    });
    var installmentValueSecondMulticardPriceToString = composition_api_1.computed(function () {
        return payment_1.toCentsString(installmentSecondMulticardValuePrice.value);
    });
    var installmentValueBankSlipPriceToString = composition_api_1.computed(function () {
        return payment_1.toCentsString(installmentBankSlipValuePrice.value);
    });
    var installmentValuePixPriceToString = composition_api_1.computed(function () {
        return payment_1.toCentsString(installmentPixValuePrice.value);
    });
    var isPayingByBankslip = composition_api_1.computed(function () { return state_1.purchase.value.payment_kind === types_1.PaymentMethods.BANK_SLIP; });
    var isPayingByCreditCard = composition_api_1.computed(function () { return state_1.purchase.value.payment_kind === types_1.PaymentMethods.CREDIT_CARD; });
    var isPayingByMulticard = composition_api_1.computed(function () { return state_1.purchase.value.payment_kind === types_1.PaymentMethods.MULTICARD; });
    var isPayingByPix = composition_api_1.computed(function () { return state_1.purchase.value.payment_kind === types_1.PaymentMethods.PIX; });
    var paymentInstallments = composition_api_1.computed(function () {
        return __spreadArray([], installment.value).reverse();
    });
    var multicardPaymentInstallments = composition_api_1.computed(function () {
        return __spreadArray([], multicardInstallment.value).reverse();
    });
    var multicardIsValid = composition_api_1.computed(function () {
        var isAdyenCart = composables_1.useCart().isAdyenCart;
        var isYunoMulticardParamsFilled = composables_1.useYuno().isYunoMulticardParamsFilled;
        if (isAdyenCart.value) {
            return (state_1.purchase.value.first_multicard_is_valid &&
                state_1.purchase.value.second_multicard_is_valid);
        }
        return isYunoMulticardParamsFilled.value;
    });
    var paymentKind = composition_api_1.computed(function () { return state_1.purchase.value.payment_kind; });
    var selectedInstallments = composition_api_1.computed(function () {
        return installment.value.find(function (_a) {
            var installments = _a.installments;
            return installments === chosenInstallment.value;
        });
    });
    var selectedInstallmentsFirstMulticard = composition_api_1.computed(function () {
        return multicardInstallment.value.find(function (_a) {
            var installments = _a.installments;
            return installments === chosenInstallmentFirstMulticard.value;
        });
    });
    var selectedInstallmentsSecondMulticard = composition_api_1.computed(function () {
        return multicardInstallment.value.find(function (_a) {
            var installments = _a.installments;
            return installments === chosenInstallmentSecondMulticard.value;
        });
    });
    var selectedInstallmentsBankSlip = composition_api_1.computed(function () {
        return bankSlipInstallment.value.find(function (_a) {
            var installments = _a.installments;
            return installments === chosenInstallmentBankSlip.value;
        });
    });
    var selectedInstallmentsPix = composition_api_1.computed(function () {
        return pixInstallment.value.find(function (_a) {
            var installments = _a.installments;
            return installments === chosenInstallmentPix.value;
        });
    });
    return {
        absorbentFees: absorbentFees,
        acceptsBankSlip: acceptsBankSlip,
        acceptsCreditCard: acceptsCreditCard,
        acceptsPix: acceptsPix,
        acceptsMulticard: acceptsMulticard,
        acceptsPixInstallment: acceptsPixInstallment,
        acceptsBankSlipInstallment: acceptsBankSlipInstallment,
        appliedCouponStatus: state_1.appliedCouponStatus,
        appliedCouponValue: state_1.appliedCouponValue,
        bankSlip: state_1.bankSlip,
        cashValue: cashValue,
        cashValueCurrency: cashValueCurrency,
        cashValueToString: cashValueToString,
        chosenInstallment: chosenInstallment,
        chosenInstallmentFirstMulticard: chosenInstallmentFirstMulticard,
        chosenInstallmentSecondMulticard: chosenInstallmentSecondMulticard,
        chosenInstallmentBankSlip: chosenInstallmentBankSlip,
        chosenInstallmentPix: chosenInstallmentPix,
        coupon: state_1.coupon,
        couponDiscount: couponDiscount,
        couponDiscountCurrency: couponDiscountCurrency,
        couponDiscountToString: couponDiscountToString,
        displayFullPrice: displayFullPrice,
        finalPrice: finalPrice,
        finalPriceCurrency: finalPriceCurrency,
        finalPriceToString: finalPriceToString,
        hasAppliedCouponStatusUnset: hasAppliedCouponStatusUnset,
        hasAppliedCouponStatusValid: hasAppliedCouponStatusValid,
        hasAppliedCouponValue: hasAppliedCouponValue,
        hasCoupon: hasCoupon,
        hasDiscount: hasDiscount,
        multicardCurrencyValue: multicardCurrencyValue,
        installmentValuePrice: installmentValuePrice,
        installmentValuePriceCurrency: installmentValuePriceCurrency,
        installmentValuePriceToString: installmentValuePriceToString,
        installmentFirstMulticardPriceCurrency: installmentFirstMulticardPriceCurrency,
        installmentSecondMulticardPriceCurrency: installmentSecondMulticardPriceCurrency,
        installmentBankSlipPriceCurrency: installmentBankSlipPriceCurrency,
        installmentPixPriceCurrency: installmentPixPriceCurrency,
        installmentValueFirstMulticardPriceToString: installmentValueFirstMulticardPriceToString,
        installmentValueSecondMulticardPriceToString: installmentValueSecondMulticardPriceToString,
        installmentBankSlipValuePrice: installmentBankSlipValuePrice,
        installmentPixValuePrice: installmentPixValuePrice,
        installmentValueBankSlipPriceToString: installmentValueBankSlipPriceToString,
        installmentValuePixPriceToString: installmentValuePixPriceToString,
        isPayingByBankslip: isPayingByBankslip,
        isPayingByCreditCard: isPayingByCreditCard,
        isPayingByMulticard: isPayingByMulticard,
        isPayingByPix: isPayingByPix,
        multicardPaymentInstallments: multicardPaymentInstallments,
        payment: state_1.payment,
        paymentInstallments: paymentInstallments,
        paymentKind: paymentKind,
        pix: state_1.pix,
        purchase: state_1.purchase,
        selectedInstallments: selectedInstallments,
        redirectURL: state_1.redirectURL,
        invitationStatus: state_1.invitationStatus,
        multicardIsValid: multicardIsValid,
        bankSlipInstallment: bankSlipInstallment,
        pixInstallment: pixInstallment,
        setAdyenCardIsValid: state_1.setAdyenCardIsValid,
        setFirstMulticardIsValid: state_1.setFirstMulticardIsValid,
        setSecondMulticardIsValid: state_1.setSecondMulticardIsValid,
        setAppliedCouponStatus: state_1.setAppliedCouponStatus,
        setAppliedCouponValue: state_1.setAppliedCouponValue,
        setBuyer: state_1.setBuyer,
        setCleanCoupon: state_1.setCleanCoupon,
        setCoupon: state_1.setCoupon,
        setCreditCard: state_1.setCreditCard,
        setFirstMulticard: state_1.setFirstMulticard,
        setSecondMulticard: state_1.setSecondMulticard,
        setInstallment: state_1.setInstallment,
        setInstallmentFirstMulticard: state_1.setInstallmentFirstMulticard,
        setInstallmentSecondMulticard: state_1.setInstallmentSecondMulticard,
        setPayment: state_1.setPayment,
        setPaymentKind: state_1.setPaymentKind,
        setRedirectURL: state_1.setRedirectURL,
        setInvitationStatus: state_1.setInvitationStatus,
        setTrackAddPaymentInfo: state_1.setTrackAddPaymentInfo,
        setTrackInitiateCheckout: state_1.setTrackInitiateCheckout,
        setPurchase: state_1.setPurchase,
        setInstallmentBankSlip: state_1.setInstallmentBankSlip,
        setInstallmentPix: state_1.setInstallmentPix,
    };
}
exports.default = default_1;
