"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var state_1 = require("./state");
function useLoading() {
    function startLoading(title, description) {
        if (title === void 0) { title = "loading.cart.title"; }
        if (description === void 0) { description = "loading.cart.description"; }
        var state = { title: title, description: description, isActive: true };
        state_1.setLoading(state);
    }
    function stopLoading() {
        var state = {
            isActive: false,
            description: "",
            title: "",
        };
        state_1.setLoading(state);
    }
    return {
        loading: state_1.loading,
        startLoading: startLoading,
        stopLoading: stopLoading,
    };
}
exports.default = useLoading;
