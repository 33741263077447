"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vee_validate_1 = require("vee-validate");
var rules_1 = require("vee-validate/dist/rules");
var validate_userdata_1 = require("../assets/scripts/validate.userdata");
vee_validate_1.localize("pt_BR");
vee_validate_1.localize({
    pt_BR: {
        messages: {
            required: "Campo obrigatório.",
            email: "Digite um e-mail válido.",
            document: "Digite um CPF/CNPJ válido.",
            telephone: "Digite um número de telefone válido.",
        },
    },
});
vee_validate_1.extend("required", __assign({}, rules_1.required));
vee_validate_1.extend("zipcode", function (value) {
    if (value.length === 9) {
        return true;
    }
    return "CEP inválido";
});
vee_validate_1.extend("email", __assign({}, rules_1.email));
vee_validate_1.extend("document", {
    validate: validate_userdata_1.validCpfCnpj,
});
vee_validate_1.extend("telephone", {
    validate: validate_userdata_1.validPhoneNumber,
});
