"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var composition_api_1 = require("@nuxtjs/composition-api");
var composables_1 = require("@/composables");
var state_1 = require("./state");
function useYuno() {
    var _a = composables_1.usePurchase(), chosenInstallment = _a.chosenInstallment, chosenInstallmentFirstMulticard = _a.chosenInstallmentFirstMulticard, chosenInstallmentSecondMulticard = _a.chosenInstallmentSecondMulticard;
    var yunoVaultedToken = composition_api_1.computed(function () { return state_1.yuno.value.vaultedToken; });
    var yunoCustomerSession = composition_api_1.computed(function () { return state_1.yuno.value.customerSession; });
    var yunoCustomerId = composition_api_1.computed(function () { return state_1.yuno.value.customerId; });
    var yunoSecureFields = composition_api_1.computed(function () { return state_1.yuno.value.secureFields; });
    var yunoHolderName = composition_api_1.computed(function () { return state_1.yuno.value.holderName; });
    var yunoCardBrand = composition_api_1.computed(function () { return state_1.yuno.value.cardBrand; });
    var yunoMulticardSecureFields = composition_api_1.computed(function () { return state_1.yuno.value.multicardSecureFields; });
    var yunoMulticardHolderName = composition_api_1.computed(function () { return state_1.yuno.value.multicardHolderName; });
    var yunoMulticardCardBrand = composition_api_1.computed(function () { return state_1.yuno.value.multicardCardBrand; });
    var yunoMulticardVaultedToken = composition_api_1.computed(function () { return state_1.yuno.value.multicardVaultedToken; });
    var yunoMulticardCustomerId = composition_api_1.computed(function () { return state_1.yuno.value.multicardCustomerId; });
    var yunoIsCvvValid = composition_api_1.computed(function () { return state_1.yuno.value.isCvvValid; });
    var yunoIsExpiryDateValid = composition_api_1.computed(function () { return state_1.yuno.value.isExpiryDateValid; });
    var yunoCreditCardParams = composition_api_1.computed(function () { return ({
        brand: yunoCardBrand.value,
        customer_session: yunoCustomerId.value,
        vaulted_token: yunoVaultedToken.value,
        installments: chosenInstallment.value,
        holder_name: yunoHolderName.value,
        country: "BR",
    }); });
    var yunoMulticardParams = composition_api_1.computed(function () { return [
        {
            brand: yunoCardBrand.value,
            customer_session: yunoCustomerId.value,
            vaulted_token: yunoVaultedToken.value,
            installments: chosenInstallmentFirstMulticard.value,
            holder_name: yunoHolderName.value,
            country: "BR",
        },
        {
            brand: yunoMulticardCardBrand.value,
            customer_session: yunoMulticardCustomerId.value,
            vaulted_token: yunoMulticardVaultedToken.value,
            installments: chosenInstallmentSecondMulticard.value,
            holder_name: yunoMulticardHolderName.value,
            country: "BR",
        },
    ]; });
    var isYunoCreditCardParamsFilled = composition_api_1.computed(function () {
        var params = yunoCreditCardParams.value;
        var fieldsToCheck = [
            "installments",
            "holder_name",
            "brand",
            "country",
        ];
        var isParamsFilled = fieldsToCheck.every(function (field) {
            return Boolean(params[field]);
        });
        return (isParamsFilled && yunoIsCvvValid.value && yunoIsExpiryDateValid.value);
    });
    var isYunoMulticardParamsFilled = composition_api_1.computed(function () {
        var fieldsToCheck = [
            "installments",
            "holder_name",
            "brand",
            "country",
        ];
        return yunoMulticardParams.value.every(function (item) {
            return fieldsToCheck.every(function (field) { return Boolean(item[field]); });
        });
    });
    return {
        yuno: state_1.yuno,
        yunoVaultedToken: yunoVaultedToken,
        yunoCustomerSession: yunoCustomerSession,
        yunoSecureFields: yunoSecureFields,
        yunoCreditCardParams: yunoCreditCardParams,
        yunoMulticardParams: yunoMulticardParams,
        isYunoCreditCardParamsFilled: isYunoCreditCardParamsFilled,
        isYunoMulticardParamsFilled: isYunoMulticardParamsFilled,
        yunoHolderName: yunoHolderName,
        yunoCustomerId: yunoCustomerId,
        yunoMulticardSecureFields: yunoMulticardSecureFields,
        yunoMulticardHolderName: yunoMulticardHolderName,
        yunoMulticardCardBrand: yunoMulticardCardBrand,
        yunoMulticardVaultedToken: yunoMulticardVaultedToken,
        yunoMulticardCustomerId: yunoMulticardCustomerId,
        setYunoMulticardCustomerId: state_1.setYunoMulticardCustomerId,
        setYunoCustomerId: state_1.setYunoCustomerId,
        setYunoVaultedToken: state_1.setYunoVaultedToken,
        setYunoSecureFields: state_1.setYunoSecureFields,
        setYunoHolderName: state_1.setYunoHolderName,
        setYunoCardBrand: state_1.setYunoCardBrand,
        setYunoMultiCardSecureFields: state_1.setYunoMultiCardSecureFields,
        setYunoMultiCardHolderName: state_1.setYunoMultiCardHolderName,
        setYunoMultiCardBrand: state_1.setYunoMultiCardBrand,
        setYunoMultiCardVaultedToken: state_1.setYunoMultiCardVaultedToken,
        setYunoIsCvvValid: state_1.setYunoIsCvvValid,
        setYunoIsExpiryDateValid: state_1.setYunoIsExpiryDateValid,
    };
}
exports.default = useYuno;
