"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var state_1 = require("./state");
function default_1() {
    function activeError(code, type, fields, cardError) {
        if (code === void 0) { code = 0; }
        if (type === void 0) { type = ""; }
        if (fields === void 0) { fields = {}; }
        if (cardError === void 0) { cardError = 0; }
        var state = {
            isActive: true,
            code: code,
            fields: fields,
            type: type,
            cardError: cardError,
        };
        state_1.setError(state);
    }
    function clearError() {
        var state = {
            isActive: false,
            code: 0,
            fields: {},
            type: "",
            cardError: null,
        };
        state_1.setError(state);
    }
    return {
        activeError: activeError,
        clearError: clearError,
        error: state_1.error,
    };
}
exports.default = default_1;
