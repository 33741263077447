"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var composition_api_1 = require("@nuxtjs/composition-api");
var state_1 = require("./state");
var CHECKOUT_FEATURES_MAP = {
    CHECKOUT_TRACKING: "checkout_tracking",
    DISABLE_MEMBERS_AREA: "disable_members_area",
    CHECKOUT_BULK_SIMULATION: "checkout_bulk_simulation",
};
function useSchoolFeatures() {
    var hasCheckoutTrackingFeature = composition_api_1.computed(function () {
        return state_1.schoolFeatures.value.includes(CHECKOUT_FEATURES_MAP.CHECKOUT_TRACKING);
    });
    var hasDisableMembersAreaFeature = composition_api_1.computed(function () {
        return state_1.schoolFeatures.value.includes(CHECKOUT_FEATURES_MAP.DISABLE_MEMBERS_AREA);
    });
    var hasCheckoutBulkSimulationFeature = composition_api_1.computed(function () {
        return state_1.schoolFeatures.value.includes(CHECKOUT_FEATURES_MAP.CHECKOUT_BULK_SIMULATION);
    });
    return {
        hasCheckoutTrackingFeature: hasCheckoutTrackingFeature,
        hasDisableMembersAreaFeature: hasDisableMembersAreaFeature,
        hasCheckoutBulkSimulationFeature: hasCheckoutBulkSimulationFeature,
        schoolFeatures: state_1.schoolFeatures,
        CHECKOUT_FEATURES_MAP: CHECKOUT_FEATURES_MAP,
        setSchoolFeatures: state_1.setSchoolFeatures,
    };
}
exports.default = useSchoolFeatures;
