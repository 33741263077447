"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.trackSegmentPageView = exports.identifySegmentUser = exports.trackSegmentEvent = exports.reset = void 0;
if (process.env.NODE_ENV !== "production") {
    window.analytics = window.analytics || {
        track: function () {
            var params = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                params[_i] = arguments[_i];
            }
            console.info("segment", params);
        },
        identify: function (userId, traits) {
            console.info("segment identify", userId, traits);
        },
        page: function () {
            console.info("segment page");
        },
    };
}
function reset() {
    window.analytics.reset();
}
exports.reset = reset;
function trackSegmentEvent(_a) {
    var event = _a.event, props = _a.props, callback = _a.callback;
    var url = new URL(window.location.href);
    url.searchParams.set("event", event);
    window.history.pushState({}, "", url.href);
    window.analytics.track(event, props, callback !== null && callback !== void 0 ? callback : {});
}
exports.trackSegmentEvent = trackSegmentEvent;
function identifySegmentUser(userId, traits) {
    window.analytics.identify(userId, traits);
}
exports.identifySegmentUser = identifySegmentUser;
function trackSegmentPageView() {
    window.analytics.page();
}
exports.trackSegmentPageView = trackSegmentPageView;
