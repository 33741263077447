"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toCurrency = exports.toCentsString = exports.toCentsNumber = void 0;
function toCentsNumber(value) {
    return value / 100;
}
exports.toCentsNumber = toCentsNumber;
function toCentsString(centsValue) {
    return centsValue.toLocaleString("pt-BR", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    });
}
exports.toCentsString = toCentsString;
function toCurrency(value, params) {
    if (params === void 0) { params = { style: "currency", currency: "BRL" }; }
    return value.toLocaleString("pt-BR", params);
}
exports.toCurrency = toCurrency;
