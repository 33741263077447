"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var composition_api_1 = require("@nuxtjs/composition-api");
var types_1 = require("@/models/types");
var composables_1 = require("@/composables");
var state_1 = require("./state");
function useCart() {
    var upsellFlow = composables_1.useUpsell().upsellFlow;
    var hasLogo = composition_api_1.computed(function () { return !!state_1.organization.value.logo_url; });
    var isSubscription = composition_api_1.computed(function () { var _a; return ((_a = state_1.cart.value) === null || _a === void 0 ? void 0 : _a.kind) === types_1.CartKind.SUBSCRIPTION; });
    var period = composition_api_1.computed(function () { return state_1.product.value.period; });
    var frequencyLimit = composition_api_1.computed(function () { return state_1.product.value.frequency_limit; });
    var frequencyType = composition_api_1.computed(function () { return state_1.product.value.frequency_type; });
    function hasCustomHeaderPage(paymentKind) {
        var thank_you_urls = state_1.product.value.thank_you_urls;
        if (!thank_you_urls && !upsellFlow.value)
            return false;
        if (paymentKind === "credit_card" && Boolean(upsellFlow.value))
            return true;
        return thank_you_urls.some(function (thanks) {
            return thanks.url_type === paymentKind && thanks.url !== "";
        });
    }
    var thankYouURL = composition_api_1.computed(function () {
        var _a, _b;
        return (_b = (_a = state_1.product.value.thank_you_urls.find(function (thank_you_url) {
            return (thank_you_url === null || thank_you_url === void 0 ? void 0 : thank_you_url.url_type) === state_1.purchase.value.payment_kind;
        })) === null || _a === void 0 ? void 0 : _a.url) !== null && _b !== void 0 ? _b : "";
    });
    var isHeroSparkOrganization = composition_api_1.computed(function () { return state_1.organization.value.core_organization_id === 119542; });
    var isAdyenCart = composition_api_1.computed(function () { return state_1.cart.value.gateway === "adyen"; });
    return {
        cart: state_1.cart,
        hasLogo: hasLogo,
        isSubscription: isSubscription,
        organization: state_1.organization,
        product: state_1.product,
        thankYouURL: thankYouURL,
        isAdyenCart: isAdyenCart,
        period: period,
        frequencyType: frequencyType,
        frequencyLimit: frequencyLimit,
        isHeroSparkOrganization: isHeroSparkOrganization,
        setCartGateway: state_1.setCartGateway,
        setCart: state_1.setCart,
        setOrganization: state_1.setOrganization,
        setProduct: state_1.setProduct,
        hasCustomHeaderPage: hasCustomHeaderPage,
    };
}
exports.default = useCart;
