"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var composition_api_1 = require("@nuxtjs/composition-api");
var vue_gtag_1 = require("vue-gtag");
var index_1 = require("./index");
function default_1() {
    var product = index_1.useCart().product;
    var userGTagID = composition_api_1.computed(function () {
        if (product.value.ga_tracking_id) {
            return product.value.ga_tracking_id;
        }
        return null;
    });
    function trackPageView(pagePath) {
        var _a;
        var checkoutGtagCode = process.env.NUXT_ENV_CHECKOUT_GTAG_ID;
        if (!checkoutGtagCode && !userGTagID.value) {
            return;
        }
        var mainGACode = (_a = (checkoutGtagCode || userGTagID.value)) !== null && _a !== void 0 ? _a : "";
        var includeGACode = checkoutGtagCode && userGTagID.value ? [{ id: userGTagID.value }] : [];
        if (product.value.google_ads_tracking_id) {
            includeGACode.push({ id: product.value.google_ads_tracking_id });
        }
        vue_gtag_1.setOptions({
            config: { id: mainGACode },
            includes: includeGACode,
        });
        vue_gtag_1.bootstrap().then(function () {
            vue_gtag_1.pageview({ page_path: pagePath });
        });
    }
    return {
        trackPageView: trackPageView,
        userGTagID: userGTagID,
    };
}
exports.default = default_1;
