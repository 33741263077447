"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Breakpoints = void 0;
var composition_api_1 = require("@nuxtjs/composition-api");
var Breakpoints;
(function (Breakpoints) {
    Breakpoints[Breakpoints["XS"] = 0] = "XS";
    Breakpoints[Breakpoints["SM"] = 576] = "SM";
    Breakpoints[Breakpoints["MD"] = 768] = "MD";
    Breakpoints[Breakpoints["LG"] = 992] = "LG";
    Breakpoints[Breakpoints["XL"] = 1200] = "XL";
})(Breakpoints = exports.Breakpoints || (exports.Breakpoints = {}));
function getScreen() {
    return window.innerWidth;
}
var width = composition_api_1.ref(getScreen());
function useScreen() {
    window.addEventListener("resize", function () {
        width.value = getScreen();
    });
    var isDesktopResolution = composition_api_1.computed(function () { return width.value >= Breakpoints.LG; });
    var isMobileResolution = composition_api_1.computed(function () { return width.value < Breakpoints.SM; });
    var isTabletResolution = composition_api_1.computed(function () { return width.value > Breakpoints.SM && width.value < Breakpoints.MD; });
    var resolution = composition_api_1.computed(function () { return width.value; });
    return {
        isDesktopResolution: isDesktopResolution,
        isMobileResolution: isMobileResolution,
        isTabletResolution: isTabletResolution,
        resolution: resolution,
    };
}
exports.default = useScreen;
