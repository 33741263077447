"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var composition_api_1 = require("@nuxtjs/composition-api");
var composables_1 = require("@/composables");
var state_1 = require("./state");
function useCheckoutSettings() {
    var isMobileResolution = composables_1.useScreen().isMobileResolution;
    var checkObjectHasKeyValues = function (obj) {
        return Object.keys(obj).some(function (key) { return obj[key]; });
    };
    var hasCustomTopBanner = composition_api_1.computed(function () {
        var _a;
        var topBannerData = (_a = state_1.checkoutSettings.value.banner_settings) === null || _a === void 0 ? void 0 : _a.top_banner;
        if (!topBannerData)
            return false;
        return checkObjectHasKeyValues(topBannerData);
    });
    var customTopBanner = composition_api_1.computed(function () {
        if (!hasCustomTopBanner.value)
            return {};
        var defaultTopBanner = {
            desktop: "",
            mobile: "",
        };
        var _a = (state_1.checkoutSettings.value.banner_settings || {}).top_banner, top_banner = _a === void 0 ? defaultTopBanner : _a;
        var _b = top_banner || {}, desktop = _b.desktop, mobile = _b.mobile;
        return isMobileResolution.value ? mobile || desktop : desktop;
    });
    var hasCustomBackground = composition_api_1.computed(function () {
        return Boolean(state_1.checkoutSettings.value.background_settings);
    });
    var customBackgroundStyle = composition_api_1.computed(function () {
        var _a;
        if (!hasCustomBackground.value)
            return {};
        var backgroundSettings = (_a = state_1.checkoutSettings.value.background_settings) !== null && _a !== void 0 ? _a : {};
        var color = backgroundSettings.color, image = backgroundSettings.image;
        var backgroundValue = color ||
            "url(" + (isMobileResolution.value
                ? (image === null || image === void 0 ? void 0 : image.mobile) || (image === null || image === void 0 ? void 0 : image.desktop)
                : image === null || image === void 0 ? void 0 : image.desktop) + ")";
        return {
            backgroundColor: color,
            backgroundImage: backgroundValue,
            backgroundSize: "cover",
            backgroundAttachment: "fixed",
        };
    });
    var hasTimerBanner = composition_api_1.computed(function () {
        return Boolean(state_1.checkoutSettings.value.timer_settings);
    });
    var timerBannerConfig = composition_api_1.computed(function () {
        return state_1.checkoutSettings.value.timer_settings;
    });
    var hasWhatsappSettings = composition_api_1.computed(function () {
        return Boolean(state_1.checkoutSettings.value.whatsapp_settings);
    });
    var whatsappSettings = composition_api_1.computed(function () {
        return state_1.checkoutSettings.value.whatsapp_settings;
    });
    var phoneIsRequired = composition_api_1.computed(function () {
        return state_1.checkoutSettings.value.require_phone;
    });
    var addressIsRequired = composition_api_1.computed(function () {
        return state_1.checkoutSettings.value.require_address;
    });
    var hasLateralBanners = composition_api_1.computed(function () {
        var _a;
        var sideBannerData = (_a = state_1.checkoutSettings.value.banner_settings) === null || _a === void 0 ? void 0 : _a.side_banner;
        if (!sideBannerData)
            return false;
        return checkObjectHasKeyValues(sideBannerData);
    });
    var lateralBannersList = composition_api_1.computed(function () {
        var _a;
        if (!hasLateralBanners.value)
            return [];
        var sideBannersList = (_a = state_1.checkoutSettings.value.banner_settings) === null || _a === void 0 ? void 0 : _a.side_banner;
        return sideBannersList
            ? Object.values(sideBannersList).filter(function (value) { return value !== null; })
            : [];
    });
    return {
        checkoutSettings: state_1.checkoutSettings,
        customTopBanner: customTopBanner,
        hasTimerBanner: hasTimerBanner,
        hasCustomTopBanner: hasCustomTopBanner,
        customBackgroundStyle: customBackgroundStyle,
        timerBannerConfig: timerBannerConfig,
        hasWhatsappSettings: hasWhatsappSettings,
        whatsappSettings: whatsappSettings,
        phoneIsRequired: phoneIsRequired,
        addressIsRequired: addressIsRequired,
        hasLateralBanners: hasLateralBanners,
        lateralBannersList: lateralBannersList,
        setCheckoutSettings: state_1.setCheckoutSettings,
    };
}
exports.default = useCheckoutSettings;
