"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var composition_api_1 = require("@nuxtjs/composition-api");
var composables_1 = require("@/composables");
exports.default = composition_api_1.defineComponent({
    props: {
        customEvent: {
            type: String,
        },
        customEventOptions: {
            type: Object,
        },
        isAddPaymentInfo: {
            type: Boolean,
        },
        isInitiateCheckout: {
            type: Boolean,
        },
        isPurchase: {
            type: Boolean,
        },
    },
    setup: function (_a) {
        var customEvent = _a.customEvent, customEventOptions = _a.customEventOptions, isAddPaymentInfo = _a.isAddPaymentInfo, isInitiateCheckout = _a.isInitiateCheckout, isPurchase = _a.isPurchase;
        var route = composition_api_1.useRoute();
        var _b = composables_1.useCart(), cart = _b.cart, organization = _b.organization, product = _b.product;
        var _c = composables_1.usePurchase(), finalPrice = _c.finalPrice, isPayingByBankslip = _c.isPayingByBankslip, isPayingByPix = _c.isPayingByPix;
        var paymentMethod = composition_api_1.computed(function () {
            if (isPayingByBankslip.value) {
                return "billet";
            }
            if (isPayingByPix.value) {
                return "pix";
            }
            return "credit_card";
        });
        var purchaseValue = composition_api_1.computed(function () { return finalPrice.value.toFixed(2); });
        var hasAlreadySendInitiateCheckout = composition_api_1.ref(false);
        var AFFILIATE = "affiliate";
        var PRODUCER = "producer";
        var additionalParams = composition_api_1.computed(function () { return ({
            payment_method: paymentMethod.value,
            offer: route.value.params.slug,
        }); });
        var calculateAffiliateCommissionValue = function () {
            return ((Number(purchaseValue.value) *
                product.value.affiliate_commission_percent) /
                100).toFixed(2);
        };
        var hasPixel = composition_api_1.computed(function () { return product.value.facebook_pixel && !!organization.value.url; });
        var purchaseParams = function (participant) {
            var value = participant === AFFILIATE
                ? calculateAffiliateCommissionValue()
                : purchaseValue.value;
            return __assign({ currency: "BRL", value: value }, additionalParams.value);
        };
        var hasPixelAffiliate = composition_api_1.computed(function () {
            return product.value.affiliate_tracking &&
                product.value.affiliate_tracking.facebook_pixel &&
                !!organization.value.url;
        });
        var pixelPageSufix = composition_api_1.computed(function () {
            if (isPurchase || isAddPaymentInfo || isInitiateCheckout) {
                return isPayingByBankslip.value ? "/pixel-billet" : "/pixel-cc";
            }
            return "/pixel-checkout-view";
        });
        var pixelId = function (participant) {
            return participant === AFFILIATE
                ? product.value.affiliate_tracking.facebook_pixel
                : product.value.facebook_pixel;
        };
        var pixelIdQueryString = function (participant) {
            return (participant === PRODUCER && hasPixel.value) ||
                (participant === AFFILIATE && hasPixelAffiliate.value)
                ? "?pixelId=" + pixelId(participant) + "&pageViewEventId=PageView_" + cart.value.id
                : "";
        };
        var purchaseQueryString = function (participant) {
            if (!isPurchase) {
                return "";
            }
            var options = encodeURIComponent(JSON.stringify(purchaseParams(participant)));
            return "&purchase=1&purchaseOptions=" + options + "&purchaseEventId=Purchase_" + cart.value.id;
        };
        var initiateCheckoutQueryString = function (participant) {
            if (!isInitiateCheckout || hasAlreadySendInitiateCheckout.value) {
                return "";
            }
            hasAlreadySendInitiateCheckout.value = true;
            var options = encodeURIComponent(JSON.stringify(purchaseParams(participant)));
            return "&initiateCheckout=1&initiateCheckoutOptions=" + options + "&initiateCheckoutEventId=InitiateCheckout_" + cart.value.id;
        };
        var addPaymentInfoQueryString = function (participant) {
            if (!isAddPaymentInfo) {
                return "";
            }
            var options = encodeURIComponent(JSON.stringify(purchaseParams(participant)));
            return "&addPaymentInfo=1&addPaymentInfoOptions=" + options + "&addPaymentInfoEventId=AddPaymentInfo_" + cart.value.id;
        };
        var customEventQueryString = composition_api_1.computed(function () {
            if (!customEvent) {
                return "";
            }
            var options = customEventOptions
                ? encodeURIComponent(JSON.stringify(__assign(__assign({}, customEventOptions), additionalParams.value)))
                : "";
            var customEventString = "&customEvent=" + customEvent;
            var customEventOptionsString = options
                ? "&customEventOptions=" + options
                : "";
            return "" + customEventString + customEventOptionsString;
        });
        var eventsUrl = function (participant) {
            return "" + purchaseQueryString(participant) + customEventQueryString.value + initiateCheckoutQueryString(participant) + addPaymentInfoQueryString(participant);
        };
        var pixelPageUrl = function (participant) {
            var queryString = "" + pixelIdQueryString(participant) + eventsUrl(participant);
            return "" + organization.value.url + pixelPageSufix.value + queryString;
        };
        var pixelPageProducer = composition_api_1.computed(function () { return pixelPageUrl(PRODUCER); });
        var pixelPageUrlAffiliate = composition_api_1.computed(function () { return pixelPageUrl(AFFILIATE); });
        return {
            cart: cart,
            hasPixel: hasPixel,
            hasPixelAffiliate: hasPixelAffiliate,
            pixelPageProducer: pixelPageProducer,
            pixelPageUrlAffiliate: pixelPageUrlAffiliate,
            purchaseParams: purchaseParams,
        };
    },
});
