import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _93146512 = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _29b8aa98 = () => interopDefault(import('../pages/500.vue' /* webpackChunkName: "pages/500" */))
const _64b44a81 = () => interopDefault(import('../pages/drop-ins/update-credit-card/index.vue' /* webpackChunkName: "pages/drop-ins/update-credit-card/index" */))
const _1e0c6bbb = () => interopDefault(import('../pages/drop-ins/update-credit-card/AdyenFormUpdate.vue' /* webpackChunkName: "pages/drop-ins/update-credit-card/AdyenFormUpdate" */))
const _3111282a = () => interopDefault(import('../pages/drop-ins/update-credit-card/YunoFormUpdate.vue' /* webpackChunkName: "pages/drop-ins/update-credit-card/YunoFormUpdate" */))
const _13ee0251 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _794f3ccc = () => interopDefault(import('../pages/_slug/index.vue' /* webpackChunkName: "pages/_slug/index" */))
const _7d394a3e = () => interopDefault(import('../pages/_slug/bankslipconfirm.vue' /* webpackChunkName: "pages/_slug/bankslipconfirm" */))
const _43407d2a = () => interopDefault(import('../pages/_slug/cardconfirm.vue' /* webpackChunkName: "pages/_slug/cardconfirm" */))
const _7ca91b70 = () => interopDefault(import('../pages/_slug/paymentconfirm.vue' /* webpackChunkName: "pages/_slug/paymentconfirm" */))
const _517ab137 = () => interopDefault(import('../pages/_slug/pixconfirm.vue' /* webpackChunkName: "pages/_slug/pixconfirm" */))
const _1d4a9e0c = () => interopDefault(import('../pages/_slug/pixsuccess.vue' /* webpackChunkName: "pages/_slug/pixsuccess" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _93146512,
    name: "404___pt_BR"
  }, {
    path: "/500",
    component: _29b8aa98,
    name: "500___pt_BR"
  }, {
    path: "/drop-ins/update-credit-card",
    component: _64b44a81,
    name: "drop-ins-update-credit-card___pt_BR"
  }, {
    path: "/drop-ins/update-credit-card/AdyenFormUpdate",
    component: _1e0c6bbb,
    name: "drop-ins-update-credit-card-AdyenFormUpdate___pt_BR"
  }, {
    path: "/drop-ins/update-credit-card/YunoFormUpdate",
    component: _3111282a,
    name: "drop-ins-update-credit-card-YunoFormUpdate___pt_BR"
  }, {
    path: "/",
    component: _13ee0251,
    name: "index___pt_BR"
  }, {
    path: "/:slug",
    component: _794f3ccc,
    name: "slug___pt_BR"
  }, {
    path: "/:slug/bankslipconfirm",
    component: _7d394a3e,
    name: "slug-bankslipconfirm___pt_BR"
  }, {
    path: "/:slug/cardconfirm",
    component: _43407d2a,
    name: "slug-cardconfirm___pt_BR"
  }, {
    path: "/:slug/paymentconfirm",
    component: _7ca91b70,
    name: "slug-paymentconfirm___pt_BR"
  }, {
    path: "/:slug/pixconfirm",
    component: _517ab137,
    name: "slug-pixconfirm___pt_BR"
  }, {
    path: "/:slug/pixsuccess",
    component: _1d4a9e0c,
    name: "slug-pixsuccess___pt_BR"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
