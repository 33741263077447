"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var composition_api_1 = require("@nuxtjs/composition-api");
var state_1 = require("./state");
function useUpsell() {
    var upsellFlow = composition_api_1.computed(function () { return state_1.upsell.value.flow; });
    var upsellSteps = composition_api_1.computed(function () { var _a; return (_a = upsellFlow.value) === null || _a === void 0 ? void 0 : _a.steps; });
    var upsellCurrentStep = composition_api_1.computed(function () { var _a; return (_a = state_1.upsell.value) === null || _a === void 0 ? void 0 : _a.currentStep; });
    var upsellActions = composition_api_1.computed(function () { var _a; return (_a = upsellCurrentStep.value) === null || _a === void 0 ? void 0 : _a.actions; });
    var upsellSelectedInstallment = composition_api_1.computed(function () { return state_1.upsell.value.selectedInstallment; });
    var upsellCardInstallments = composition_api_1.computed(function () { var _a, _b; return ((_b = (_a = upsellCurrentStep.value) === null || _a === void 0 ? void 0 : _a.payment_simulation) === null || _b === void 0 ? void 0 : _b.credit_card_simulation) || []; });
    var upsellPaymentData = composition_api_1.computed(function () { return (__assign(__assign({}, state_1.upsell.value.paymentInfo), { token: state_1.upsell.value.paymentToken, cartId: state_1.upsell.value.cartId })); });
    function setUpsellPaymentData(paymentInfo, paymentToken, cartId) {
        state_1.setUpsellPaymentInfo(paymentInfo);
        state_1.setUpsellPaymentToken(paymentToken);
        state_1.setUpsellCartId(cartId);
    }
    function getUpsellStep(step) {
        if (!upsellSteps.value)
            return {};
        return upsellSteps.value[step - 1];
    }
    function checkStepCode(stepCode) {
        var currentStepId = upsellCurrentStep.value.public_id;
        return currentStepId === stepCode;
    }
    function checkUpsellOrigin(origin, stepCode) {
        var domainPattern = /^https?:\/\/(?:www\.)?(?:[\w-]+\.)*herospark\.com$/;
        var localhostPattern = /^https?:\/\/localhost(:\d+)?$/;
        var isDevelopment = process.env.nuxtSiteEnv === "development";
        var isHerosparkDomain = domainPattern.test(origin);
        var isLocalhost = isDevelopment && localhostPattern.test(origin);
        return (isHerosparkDomain || isLocalhost) && checkStepCode(stepCode);
    }
    function upsellMessageEventListener(actionAccept, actionReject) {
        return function (event) {
            var _a = event.data.split("/"), message = _a[0], stepCode = _a[1];
            if (!checkUpsellOrigin(event.origin, stepCode))
                return;
            if (message === "upsell-accept") {
                actionAccept();
            }
            if (message === "upsell-reject") {
                actionReject();
            }
        };
    }
    return {
        upsell: state_1.upsell,
        upsellSteps: upsellSteps,
        upsellFlow: upsellFlow,
        upsellSelectedInstallment: upsellSelectedInstallment,
        upsellCurrentStep: upsellCurrentStep,
        upsellCardInstallments: upsellCardInstallments,
        upsellPaymentData: upsellPaymentData,
        upsellActions: upsellActions,
        getUpsellStep: getUpsellStep,
        setUpsellFlow: state_1.setUpsellFlow,
        setUpsellCurrentStep: state_1.setUpsellCurrentStep,
        setUpsellSelectedInstallment: state_1.setUpsellSelectedInstallment,
        upsellMessageEventListener: upsellMessageEventListener,
        setUpsellPaymentInfo: state_1.setUpsellPaymentInfo,
        setUpsellPaymentToken: state_1.setUpsellPaymentToken,
        setUpsellCartId: state_1.setUpsellCartId,
        setUpsellPaymentData: setUpsellPaymentData,
    };
}
exports.default = useUpsell;
