export const AddressData = () => import('../../components/AddressData.vue' /* webpackChunkName: "components/address-data" */).then(c => wrapFunctional(c.default || c))
export const Badge = () => import('../../components/Badge.vue' /* webpackChunkName: "components/badge" */).then(c => wrapFunctional(c.default || c))
export const Boleto = () => import('../../components/Boleto.vue' /* webpackChunkName: "components/boleto" */).then(c => wrapFunctional(c.default || c))
export const CardContainer = () => import('../../components/CardContainer.vue' /* webpackChunkName: "components/card-container" */).then(c => wrapFunctional(c.default || c))
export const CheckoutInfo = () => import('../../components/CheckoutInfo.vue' /* webpackChunkName: "components/checkout-info" */).then(c => wrapFunctional(c.default || c))
export const CreditCard = () => import('../../components/CreditCard.vue' /* webpackChunkName: "components/credit-card" */).then(c => wrapFunctional(c.default || c))
export const CronometerBanner = () => import('../../components/CronometerBanner.vue' /* webpackChunkName: "components/cronometer-banner" */).then(c => wrapFunctional(c.default || c))
export const ErrorModal = () => import('../../components/ErrorModal.vue' /* webpackChunkName: "components/error-modal" */).then(c => wrapFunctional(c.default || c))
export const FinishTransaction = () => import('../../components/FinishTransaction.vue' /* webpackChunkName: "components/finish-transaction" */).then(c => wrapFunctional(c.default || c))
export const FooterInfo = () => import('../../components/FooterInfo.vue' /* webpackChunkName: "components/footer-info" */).then(c => wrapFunctional(c.default || c))
export const InstallmentsSelect = () => import('../../components/InstallmentsSelect.vue' /* webpackChunkName: "components/installments-select" */).then(c => wrapFunctional(c.default || c))
export const LoadingScreen = () => import('../../components/LoadingScreen.vue' /* webpackChunkName: "components/loading-screen" */).then(c => wrapFunctional(c.default || c))
export const Multicard = () => import('../../components/Multicard.vue' /* webpackChunkName: "components/multicard" */).then(c => wrapFunctional(c.default || c))
export const OrderBumpItem = () => import('../../components/OrderBumpItem.vue' /* webpackChunkName: "components/order-bump-item" */).then(c => wrapFunctional(c.default || c))
export const OrderBumpSection = () => import('../../components/OrderBumpSection.vue' /* webpackChunkName: "components/order-bump-section" */).then(c => wrapFunctional(c.default || c))
export const Orderbump = () => import('../../components/Orderbump.vue' /* webpackChunkName: "components/orderbump" */).then(c => wrapFunctional(c.default || c))
export const Payment = () => import('../../components/Payment.vue' /* webpackChunkName: "components/payment" */).then(c => wrapFunctional(c.default || c))
export const Pix = () => import('../../components/Pix.vue' /* webpackChunkName: "components/pix" */).then(c => wrapFunctional(c.default || c))
export const PixRecommendation = () => import('../../components/PixRecommendation.vue' /* webpackChunkName: "components/pix-recommendation" */).then(c => wrapFunctional(c.default || c))
export const ProductDetails = () => import('../../components/ProductDetails.vue' /* webpackChunkName: "components/product-details" */).then(c => wrapFunctional(c.default || c))
export const ProductDetailsCoupon = () => import('../../components/ProductDetailsCoupon.vue' /* webpackChunkName: "components/product-details-coupon" */).then(c => wrapFunctional(c.default || c))
export const ProductDetailsOrganization = () => import('../../components/ProductDetailsOrganization.vue' /* webpackChunkName: "components/product-details-organization" */).then(c => wrapFunctional(c.default || c))
export const ProductDetailsPriceHeader = () => import('../../components/ProductDetailsPriceHeader.vue' /* webpackChunkName: "components/product-details-price-header" */).then(c => wrapFunctional(c.default || c))
export const ProductDetailsPriceTotal = () => import('../../components/ProductDetailsPriceTotal.vue' /* webpackChunkName: "components/product-details-price-total" */).then(c => wrapFunctional(c.default || c))
export const ReceiverInfo = () => import('../../components/ReceiverInfo.vue' /* webpackChunkName: "components/receiver-info" */).then(c => wrapFunctional(c.default || c))
export const SelectInput = () => import('../../components/SelectInput.vue' /* webpackChunkName: "components/select-input" */).then(c => wrapFunctional(c.default || c))
export const TabButton = () => import('../../components/TabButton.vue' /* webpackChunkName: "components/tab-button" */).then(c => wrapFunctional(c.default || c))
export const UserData = () => import('../../components/UserData.vue' /* webpackChunkName: "components/user-data" */).then(c => wrapFunctional(c.default || c))
export const WhatsappWidget = () => import('../../components/WhatsappWidget.vue' /* webpackChunkName: "components/whatsapp-widget" */).then(c => wrapFunctional(c.default || c))
export const YunoCreditCard = () => import('../../components/YunoCreditCard.vue' /* webpackChunkName: "components/yuno-credit-card" */).then(c => wrapFunctional(c.default || c))
export const YunoCreditCardForm = () => import('../../components/YunoCreditCardForm.vue' /* webpackChunkName: "components/yuno-credit-card-form" */).then(c => wrapFunctional(c.default || c))
export const YunoMulticard = () => import('../../components/YunoMulticard.vue' /* webpackChunkName: "components/yuno-multicard" */).then(c => wrapFunctional(c.default || c))
export const CheckoutHeaderAccessContent = () => import('../../components/CheckoutHeader/CheckoutHeaderAccessContent.vue' /* webpackChunkName: "components/checkout-header-access-content" */).then(c => wrapFunctional(c.default || c))
export const CheckoutHeaderBankSlip = () => import('../../components/CheckoutHeader/CheckoutHeaderBankSlip.vue' /* webpackChunkName: "components/checkout-header-bank-slip" */).then(c => wrapFunctional(c.default || c))
export const CheckoutHeaderCreditCard = () => import('../../components/CheckoutHeader/CheckoutHeaderCreditCard.vue' /* webpackChunkName: "components/checkout-header-credit-card" */).then(c => wrapFunctional(c.default || c))
export const CheckoutHeaderPix = () => import('../../components/CheckoutHeader/CheckoutHeaderPix.vue' /* webpackChunkName: "components/checkout-header-pix" */).then(c => wrapFunctional(c.default || c))
export const CheckoutHeader = () => import('../../components/CheckoutHeader/index.vue' /* webpackChunkName: "components/checkout-header" */).then(c => wrapFunctional(c.default || c))
export const FinishTransactionDesktop = () => import('../../components/FinishTransaction/Desktop.vue' /* webpackChunkName: "components/finish-transaction-desktop" */).then(c => wrapFunctional(c.default || c))
export const FinishTransactionMobile = () => import('../../components/FinishTransaction/Mobile.vue' /* webpackChunkName: "components/finish-transaction-mobile" */).then(c => wrapFunctional(c.default || c))
export const UpsellModal = () => import('../../components/Upsell/UpsellModal.vue' /* webpackChunkName: "components/upsell-modal" */).then(c => wrapFunctional(c.default || c))
export const UpsellPaymentMethods = () => import('../../components/Upsell/UpsellPaymentMethods.vue' /* webpackChunkName: "components/upsell-payment-methods" */).then(c => wrapFunctional(c.default || c))
export const UpsellPurchaseSummary = () => import('../../components/Upsell/UpsellPurchaseSummary.vue' /* webpackChunkName: "components/upsell-purchase-summary" */).then(c => wrapFunctional(c.default || c))
export const SharedCheckoutImage = () => import('../../components/shared/CheckoutImage.vue' /* webpackChunkName: "components/shared-checkout-image" */).then(c => wrapFunctional(c.default || c))
export const SharedFBPixel = () => import('../../components/shared/FBPixel.vue' /* webpackChunkName: "components/shared-f-b-pixel" */).then(c => wrapFunctional(c.default || c))
export const SharedGATrack = () => import('../../components/shared/GATrack.vue' /* webpackChunkName: "components/shared-g-a-track" */).then(c => wrapFunctional(c.default || c))
export const SharedMButton = () => import('../../components/shared/MButton.vue' /* webpackChunkName: "components/shared-m-button" */).then(c => wrapFunctional(c.default || c))
export const SharedMInputCheckbox = () => import('../../components/shared/MInputCheckbox.vue' /* webpackChunkName: "components/shared-m-input-checkbox" */).then(c => wrapFunctional(c.default || c))
export const SharedModal = () => import('../../components/shared/Modal.vue' /* webpackChunkName: "components/shared-modal" */).then(c => wrapFunctional(c.default || c))
export const SharedToaster = () => import('../../components/shared/Toaster.vue' /* webpackChunkName: "components/shared-toaster" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
