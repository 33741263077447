"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var composition_api_1 = require("@nuxtjs/composition-api");
var state_1 = require("./state");
function useHoneyPot() {
    var honeyPotInput = composition_api_1.computed(function () { return state_1.honeyPot.value.honeyPotInput; });
    return {
        honeyPot: state_1.honeyPot,
        honeyPotInput: honeyPotInput,
    };
}
exports.default = useHoneyPot;
