"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var composition_api_1 = require("@nuxtjs/composition-api");
var vue_gtag_1 = require("vue-gtag");
var index_1 = require("./index");
function default_1() {
    var _a = index_1.useCart(), cart = _a.cart, product = _a.product;
    var finalPrice = index_1.usePurchase().finalPrice;
    function trackPurchase() {
        if (product.value.google_ads_tracking_label) {
            vue_gtag_1.event("conversion", {
                send_to: product.value.google_ads_tracking_label,
                value: finalPrice,
            });
        }
        vue_gtag_1.event("purchase", {
            transaction_id: "order-" + cart.value.id,
            value: finalPrice,
            affiliation: product.value.name,
            currency: "BRL",
            tax: 0,
            shipping: 0,
            items: [
                {
                    id: cart.value.id,
                    name: product.value.name,
                    list_name: "",
                    brand: "",
                    category: "",
                    variant: "",
                    list_position: 1,
                    quantity: 1,
                    price: finalPrice,
                },
            ],
        });
    }
    composition_api_1.onMounted(function () { return trackPurchase(); });
    return {
        trackPurchase: trackPurchase,
    };
}
exports.default = default_1;
